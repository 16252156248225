import React from 'react';
// import videojs from 'video.js';
import VREPlayer from 'videojs-react-enhanced';
import 'video.js/dist/video-js.css';
import './videojs.scss';


function VideoComponent({video, posterImg}) {

  // console.log(posterImg)
  const playerOptions = {
  };
  const videojsOptions = {
    src: video,
    fluid: true,
    // poster: posterImg,
    controls: true
    };
  

  return (
    <VREPlayer
      playerOptions={playerOptions}
      videojsOptions={videojsOptions}
      // onReady={(player) => console.log(player)}
      // onPlay={(e, _, second) => console.log('Play!')}
      // onPause={(e, _, second) => console.log('Pause!')}
      // onEnded={(e, _) => console.log('Ended!')}
    />
  );
}

export default VideoComponent;