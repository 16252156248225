import { Link } from "gatsby";
import * as React from "react"
import '../../styles/main.scss'
import Layout from "../../components/layouts/layout";
import { SeoC } from "../../components/utils/seo";
import { graphql } from "gatsby";

import VideoComponent from '../../components/video/videoComponent'
// import Logo from '../components/utils/logo'
import Back from '../../images/back.svg'
import Portfolio from "../../components/portfolio/portfolio";
import LogoSVG from '../../images/aaha.svg'

// markup
const WorkTemplate = ({data}) => {

  const singleWork = data.datoCmsWork

  const moreWorks = data.allDatoCmsWork.edges
  
  return (
    <Layout>
      {singleWork.slug && (   
      <div className="work-page">
          <div className="container">
        <div className="work-header">
            <span className="back"><Link to={`/`}><img src={Back} alt="Go back"/></Link></span>
            <span className="logo"></span>
        </div>   
            </div>
            <div className="the-video">
              <VideoComponent video={`/vid/` + singleWork.videoFile} posterImg={singleWork.thumbnail.resolutions.src}/>
              {/* <YouTubeEmbed title={singleWork.title} video={singleWork.reels.youtube}/> */}
            </div>
            <div className="container">
            <div className="video-info">
              <div className="head">
              <h2>{singleWork.title}</h2>
              {singleWork.typeOfFilm && (
                  <span>{singleWork.typeOfFilm}</span>
                )}
              </div>
              <ul className="meta">
                {singleWork.client && (
                  <li><span>Client </span>{singleWork.client}</li>
                )}
                {singleWork.agency && (
                  <li><span>Agency </span>{singleWork.agency}</li>
                )}
                {singleWork.director && (
                  <li><span>Director </span>{singleWork.director}</li>
                )}
              </ul>
            </div>
          </div>
          <div className="contact-box">
            <img className="cnt_logo" src={LogoSVG} alt="Aaha!" />
            <a href="mailto:hey@aaha.one">Get in touch.</a>
          </div>
          <Portfolio videos={moreWorks} />
      </div>
      )}
    </Layout>
  )
}

export const Head = ({data}) => (
  <SeoC title={data.datoCmsWork.title + ` - ` + data.datoCmsWork.client} pathname={`/films/` + data.datoCmsWork.slug} image={data.datoCmsWork.thumbnail.resolutions.src} />
)

export default WorkTemplate

export const workQuery = graphql`
  query WorkByPath($id: String) {
    datoCmsWork(id: { eq: $id }) {
      id
      title
      client
      director
      agency
      slug
      typeOfFilm
      videoFile
      thumbnail {
        resolutions {
            src
          }
      }
    }
    allDatoCmsWork (filter: {id: {ne: $id}}, sort: {fields: sortOrder, order: ASC}){
    edges {
      node {
        title
        videoFile
        agency
        client
        director
        id
        slug
        thumbnail {
          gatsbyImageData
        }
      }
    }
  }    
  }
`;

